@import url('https://fonts.googleapis.com/css?family=Mukta');
body {
  font-family: 'Mukta', sans-serif;
  background-color: #83b169; 
  color: black;
  margin: 0;
  overflow-x: hidden;
}
.blackContent {
  background-color: black;
  color: white;
}
html {
  scroll-behavior: smooth !important;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, .25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  margin: 5px;
  min-height: 100px;
}
.card-footer {
  font-size: 0.65em;
  color: #446;
}
.link {
  display: inline-block;
  position: relative;
  color: white;
}
.link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.20s ease-out;
}
.link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.linkBlack {
  display: inline-block;
  position: relative;
  color: black;
}
.linkBlack::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.20s ease-out;
}
.linkBlack:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.allContent {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}